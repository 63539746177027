<template>
  <div>
    <b-button v-if="checkRequiredPermissions([$roles.CONTACT_THREAD_PAYMENT_SCHEDULE_ADD])"
              variant="primary"
              size="sm"
              class="mb-50"
              @click="openScheduleModal(defaultScheduleItem)"
    >
      <sw-icon icon="PlusIcon" />
      {{ $t('AddPaymentSchedules') }}
    </b-button>
    <!-- Content -->
    <!-- eslint-disable -->
    <b-card>
      <sw-table
        table-id="2645411d81567e412354f3dc7ff4b3aafcf"
        show-columns
        :show-search="false"
        :fields.async="fields"
        :pagination="pagination"
        @change-pagination="Object.assign(pagination, $event)"
        @change-search-query="search = $event"
        @reload-content="initData"
        @set-fields="fields = $event"
      >
        <template #table>
          <!--      Table      -->
          <b-table
            :items="items"
            :fields="tableFields"
            striped
            responsive
            :style="{ minHeight: tableSize(items, 250) }"
            :busy="loading"
            show-empty
            :sort-by.sync="sorter.sortBy"
            :sort-desc.sync="sorter.sortDesc"
            :no-local-sorting="true"
            @sort-changed="Object.assign(sorter, $event); initData()"
          >
            <!--    Head    -->
            <template #head()="{ label }">
              {{ $t(`${label}`) }}
            </template>
            <template #cell(id)="{ index }">
              #{{ getOrderNo(index, pagination, sorter) }}
            </template>

              <template #cell(netPrice)="{ item }">
              {{ getTotalNetPrice(item) | priceFormat }} {{ settings.defaultCurrency || 'PLN' }}
            </template>

            <template #cell(status)="{ value }">
                <b-badge :variant="getStatusInfo(value).variant">
                    {{ $t(getStatusInfo(value).label) }}
                </b-badge>
            </template>
            <template #cell(type)="{ value }">
              <b-badge>
                <!--                <sw-icon :icon="getType(value).icon" />-->
                {{ $t(value) }}
              </b-badge>
            </template>
              <template #cell(contactThread)="{ item }">
        <span v-if="item && item.contactThread">
          <router-link :class="{ 'deleted-text': item.contactThread.contact && item.contactThread.contact.deletedAt }"
                       :to="`/contact/${item.contactThread.contact.id}/thread/${item.contactThread.id}/payments`"
          >
              {{ getUserName(item.contactThread.contact) }} - {{ item.contactThread.name }}
          </router-link>
        </span>
                  <span v-else>
          <feather-icon icon="MinusIcon" />
        </span>
              </template>
            <!--    Other    -->
            <template #cell()="row">
              <table-default-cell
                :field="row.field"
                :value="row.value"
              />
            </template>

            <template #cell(type)="{ value }">
                <span v-if="value && accountTypes.find(e => e.type === value)">
                    {{ $t(accountTypes.find(e => e.type === value).label) }}
                </span>
                <sw-icon v-else icon="MinusIcon" />
            </template>
            <template #cell(documentType)="{ value }">
                <span v-if="value && documentTypes.find(e => e.type === value)">
                    {{ $t(documentTypes.find(e => e.type === value).label) }}
                </span>
                <sw-icon v-else icon="MinusIcon" />
            </template>
            <template #cell(documentIssuingType)="{ value }">
                <span v-if="value && documentIssuingTypes.find(e => e.type === value)">
                    {{ $t(documentIssuingTypes.find(e => e.type === value).label) }}
                </span>
                <sw-icon v-else icon="MinusIcon" />
            </template>
            <template #cell(serviceSellDate)="{ value }">
                <span v-if="value && serviceSellDates.find(e => e.type === value)">
                    {{ $t(serviceSellDates.find(e => e.type === value).label) }}
                </span>
                <span v-else>{{ $t('CurrentMonth') }}</span>
            </template>

            <template #cell(action)="{ item, index }">
              <b-dropdown
                id="dropdown-grouped"
                dropleft
                no-caret
                variant="flat"
                v-if="checkRequiredPermissions([$roles.CONTACT_THREAD_PAYMENT_SCHEDULE_EDIT])"
              >
                <template #button-content>
                  <feather-icon icon="MoreVerticalIcon" />
                </template>
                <div>
                  <b-dropdown-item v-if="item.status === 'ACTIVE'" @click="changePaymentScheduleStatus(item, index, 'HOLD')">
                    <dropdown-item-label
                            icon="PauseIcon"
                                         :label="$t('Hold')"
                    />
                  </b-dropdown-item>
                    <b-dropdown-item v-else @click="changePaymentScheduleStatus(item, index, 'ACTIVE')">
                    <dropdown-item-label
                            icon="PlayIcon"
                                         :label="$t('Resume')"
                    />
                  </b-dropdown-item>
                  <b-dropdown-item @click="openScheduleModal(item, index)">
                    <dropdown-item-label icon="EditIcon"
                                         :label="$t('EditSchedule')"
                    />
                  </b-dropdown-item>
                </div>
              </b-dropdown>
            </template>

            <!--      Empty      -->
            <template #empty>
              <empty-content />
            </template>

            <!--      Table Busy      -->
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner
                  class="align-middle"
                  variant="primary"
                />
              </div>
            </template>
          </b-table>
        </template>
      </sw-table>
    </b-card>

    <b-modal v-model="scheduleModal.show"
             :title="$t(scheduleModal.item && !scheduleModal.item.id ? 'Create' : 'Edit')"
             size="lg"
             hide-footer
    >
      <PaymentScheduleForm
        v-if="scheduleModal.show"
        :edited-item="scheduleModal.item"
        @save="saveModalForm"
      />
    </b-modal>
  </div>
</template>

<script>
import PaymentScheduleForm from '@/pages/contact/components/PaymentScheduleForm.vue'
import { BTable } from 'bootstrap-vue'
import axiosIns from '@/libs/axios'
import { mapGetters } from 'vuex'

const defaultScheduleItem = {
  name: '',
}

export default {
  name: 'PaymentSchedule',
  components: { PaymentScheduleForm, BTable },
  props: {
    contactId: {
      required: false,
      default: null,
    },
    threadId: {
      required: false,
      default: null,
    },
  },
  data: () => ({
    loading: false,
    items: [],
    sorter: {
      sortBy: 'id',
      sortDesc: true,
    },
    pagination: {
      perPage: 25,
      currentPage: 1,
      totalRecords: 0,
    },
    search: '',
    defaultScheduleItem,
    scheduleModal: {
      show: false,
      item: JSON.parse(JSON.stringify(defaultScheduleItem)),
      index: -1,
    },
    accountTypes: [
      { type: 'CUSTOM', label: 'CustomSchedule' },
      { type: 'GOOGLE_ADS', label: 'GoogleAdsSchedule' },
    ],
    documentTypes: [
      { type: 'PRO_FORMA', label: 'ProFormaInvoice' },
      { type: 'VAT', label: 'VatInvoice' },
    ],
    documentIssuingTypes: [
      { type: 'MONTHLY', label: 'OncePerMonth' },
      { type: 'DEPENDENCY', label: 'ManyTimesAMonth' },
    ],
    serviceSellDates: [
      { type: 'CURRENT_MONTH', label: 'CurrentMonth' },
    ],
    fields: [
      {
        label: 'Thread', key: 'contactThread', visible: true, onlyGlobal: true,
      },
      { label: 'ScheduleTypeSelection', key: 'type', visible: true },
      { label: 'NetAmount', key: 'netPrice', visible: true },
      { label: 'GeneratedDocumentType', key: 'documentType', visible: true },
      { label: 'AutomaticVatInvoiceGeneration', key: 'generateVatAfterPaid', visible: true },
      { label: 'IssueFrequency', key: 'documentIssuingType', visible: true },
      { label: 'DocumentsPerMonth', key: 'maxPaymentsPerMonth', visible: true },
      { label: 'SalesDate', key: 'serviceSellDate', visible: true },
      { label: 'PaymentTerm', key: 'paymentDaysCount', visible: true },
      { label: 'EndScheduleDate', key: 'scheduleEndDate', visible: true },
      { label: 'Status', key: 'status', visible: true },

      // { label: 'SpecifyStartDocumentDate', key: 'startDayOfDocumentIssue', visible: true },
      // { label: 'SpecifyEndScheduleDate', key: 'turnOffSchedule', visible: true },
      // { key: 'netAmount', label: 'NetAmount', visible: true },
      // {
      //   key: 'documentGenerationDate', label: 'DocumentGenerationDate', visible: true, tag: 'b-badge',
      // },
      // {
      //   key: 'generatedDocumentType', label: 'GeneratedDocumentType', visible: true, tag: 'b-badge',
      // },
      // {
      //   key: 'scheduleType', label: 'ScheduleType', visible: true, tag: 'b-badge',
      // },
      // {
      //   key: 'documentIssueMode', label: 'DocumentIssueMode', visible: true, tag: 'b-badge',
      // },
      // {
      //   key: 'documentsPerMonth', label: 'DocumentsPerMonth', visible: true, tag: 'b-badge',
      // },
      // {
      //   key: 'automaticVatInvoiceGeneration', label: 'AutomaticVatInvoiceGeneration', visible: true,
      // },
      // {
      //   key: 'paymentDue', label: 'PaymentDue', visible: true, tag: 'b-badge',
      // },
      // { key: 'scheduleEndDate', label: 'ScheduleEndDate', visible: true },
      // {
      //   key: 'scheduleStatus', label: 'ScheduleStatus', visible: true, tag: 'b-badge',
      // },
      {
        key: 'action', label: 'Action', visible: true,
      },
    ],
  }),
  computed: {
    ...mapGetters({ settings: 'system/getSettings' }),
    visibleFields() {
      return this.fields
        .filter(field => !field?.requiredModule || this.checkRequiredModule(field?.requiredModule))
        .filter(field => !field.onlyGlobal || (this.$route.name === 'schedules' && field.onlyGlobal))
    },
    tableFields() {
      return this.visibleFields.filter(field => field.visible)
    },
  },
  mounted() {
    this.initData()
  },
  methods: {
    async saveModalForm() {
      this.$set(this, 'scheduleModal', {
        show: false,
        item: JSON.parse(JSON.stringify(defaultScheduleItem)),
        index: -1,
      })

      this.initData()
    },
    getStatusInfo(status) {
      if (status === 'ACTIVE') return { variant: 'light-success', label: 'Active' }
      if (status === 'HOLD') return { variant: 'light-warning', label: 'Holded' }

      return { variant: 'secondary', label: '-' }
    },
    async changePaymentScheduleStatus(item, index, status = '') {
      const confirmed = await this.showAlert('warning', this.$i18n.t('alerts.AreYouSure'))
      if (!confirmed) return

      try {
        await axiosIns.patch('1/contacts/threads/paymentSchedule', { id: item.id, status })
        this.showToast('success', this.$i18n.t('success.contactUpdated'))

        this.items[index].status = status
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    getTotalNetPrice(item) {
      return item.contactThreadPaymentScheduleItems.reduce((a, b) => +a + +b.priceNetTotal, 0)
    },
    openScheduleModal(item = defaultScheduleItem, index = -1) {
      this.$set(this, 'scheduleModal', {
        show: true,
        item: JSON.parse(JSON.stringify(item)),
        index,
      })
    },
    async initData() {
      try {
        this.loading = true
        const params = {
          fields_load: 'PAYMENT_SCHEDULE',
          orderBy: 'id.desc',
        }

        if (this.pagination) {
          params.page = this.pagination.currentPage
          params.limit = this.pagination.perPage
        }

        if (this.sorter) {
          const sortDir = this.sorter?.sortDesc ? 'desc' : 'asc'
          const sortBy = this.sorter?.sortBy || 'id'
          params.orderBy = `${sortBy}.${sortDir}`
        }

        if (this.threadId) {
          params.eq_contactThread = this.threadId
        }

        const resp = await axiosIns.get('1/contacts/threads/paymentSchedule', { params })

        console.log(resp.data.data?.items[0])
        this.items = resp.data.data?.items
        this.pagination.totalRecords = resp.data.data?.totalItemCount || 0
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>

</style>
