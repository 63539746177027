<template>
  <div>
    <sw-select :name="$t('ScheduleTypeSelection')">
      <v-select v-model="item.type"
                :reduce="e => e.type"
                :options="accountTypes"
                disabled
      >
        <template #selected-option="{ label }">
          {{ $t(label) }}
        </template>
        <template #option="{ label }">
          {{ $t(label) }}
        </template>
      </v-select>
    </sw-select>

    <!--    <div class="d-flex align-items-end">-->
    <!--      <b-form-group :label="$t('EnterNetAmount')"-->
    <!--                    class="w-100"-->
    <!--      >-->
    <!--        <b-form-input v-model="item.netPrice"-->
    <!--                      type="number"-->
    <!--        />-->
    <!--      </b-form-group>-->
    <!--      <sw-select style="max-width: 200px"-->
    <!--                 class="ml-50"-->
    <!--      >-->
    <!--        <v-select v-model="item.netPriceCurrency"-->
    <!--                  :options="currencyList"-->
    <!--        >-->
    <!--          <template #no-options>-->
    <!--            {{ $t('NoOptions') }}-->
    <!--          </template>-->
    <!--        </v-select>-->
    <!--      </sw-select>-->
    <!--    </div>-->

    <!--    <sw-select :name="$t('SelectDocumentType')">-->
    <!--      <v-select v-model="item.documentType"-->
    <!--                :reduce="e => e.type"-->
    <!--                :options="documentTypes"-->
    <!--      >-->
    <!--        <template #selected-option="{ label }">-->
    <!--          {{ $t(label) }}-->
    <!--        </template>-->
    <!--        <template #option="{ label }">-->
    <!--          {{ $t(label) }}-->
    <!--        </template>-->
    <!--      </v-select>-->
    <!--    </sw-select>-->

    <!--    <b-form-group>-->
    <!--      <b-form-checkbox v-model="item.generateVatAfterPaid">-->
    <!--        {{ $t('GenerateAutoVatInvoice') }}-->
    <!--      </b-form-checkbox>-->
    <!--    </b-form-group>-->

    <sw-select :name="$t('IssueFrequency')">
      <v-select v-model="item.documentIssuingType"
                :options="documentIssuingTypes"
                :reduce="e => e.type"
                label="label"
      >
        <template #selected-option="{ label }">
          {{ $t(label) }}
        </template>
        <template #option="{ label }">
          {{ $t(label) }}
        </template>
        <template #no-options>
          {{ $t('NoOptions') }}
        </template>
      </v-select>
    </sw-select>

    <b-form-group v-if="item.documentIssuingType === 'DEPENDENCY'"
                  :label="$t('DocumentsPerMonthCount')"
                  class="w-100"
    >
      <b-form-input v-model="item.maxPaymentsPerMonth"
                    type="number"
      />
    </b-form-group>

    <sw-select :name="$t('SalesDate')">
      <v-select v-model="item.serviceSellDate"
                :options="serviceSellDates"
                :reduce="e => e.type"
                disabled
                label="label"
      >
        <template #selected-option="{ label }">
          {{ $t(label) }}
        </template>
        <template #option="{ label }">
          {{ $t(label) }}
        </template>
        <template #no-options>
          {{ $t('NoOptions') }}
        </template>
      </v-select>
    </sw-select>

    <b-form-group :label="$t('PaymentTerm')"
                  class="w-100"
    >
      <b-form-input v-model="item.paymentDaysCount"
                    type="number"
      />
    </b-form-group>

    <div class="divider divider-left">
      <div class="divider-text text-primary">
        {{ $t('PaymentPositions') }}
      </div>
    </div>

    <b-button size="sm"
              variant="primary"
              @click="positionModal.show = true"
    >
      <sw-icon icon="PlusIcon" />
      {{ $t('Add') }}
    </b-button>

    <b-table
      :items="item.contactThreadPaymentScheduleItems"
      :fields="positionFields"
      class="mt-25"
      striped
      responsive
      :style="{ minHeight: tableSize(item.contactThreadPaymentScheduleItems, 100) }"
      show-empty
      :no-local-sorting="true"
    >
      <!--    Head    -->
      <template #head()="{ label }">
        {{ $t(`${label}`) }}
      </template>

      <template #cell(priceNet)="{ value, item }">
        <div class="text-nowrap">
          1x <strong>{{ value | priceFormat }}</strong> {{ settings.defaultCurrency || 'PLN' }}
        </div>
        <div class="text-nowrap">
          {{ item.quantity }}x <strong>{{ item.priceNetTotal | priceFormat }}</strong> {{ settings.defaultCurrency || 'PLN' }}
        </div>
      </template>
      <template #cell(vatPercentage)="{ value }">
        {{ value }}%
      </template>
      <template #cell(priceGross)="{ value, item }">
        <div class="text-nowrap">
          1x <strong>{{ value | priceFormat }}</strong> {{ settings.defaultCurrency || 'PLN' }}
        </div>
        <div class="text-nowrap">
          {{ item.quantity }}x <strong>{{ item.priceGrossTotal | priceFormat }}</strong> {{ settings.defaultCurrency || 'PLN' }}
        </div>
      </template>
      <template #cell(quantityUnit)="{ value }">
        {{ $t(`unit.${value}`) }}
      </template>

      <!--    Other    -->
      <template #cell()="row">
        <table-default-cell
          :field="row.field"
          :value="row.value"
        />
      </template>

      <template #cell(action)="{ item, index }">
        <b-dropdown
          id="dropdown-grouped"
          dropleft
          no-caret
          variant="flat"
        >
          <template #button-content>
            <feather-icon icon="MoreVerticalIcon" />
          </template>
          <div>
            <b-dropdown-item @click="positionModal.show = true; positionModal.item = JSON.parse(JSON.stringify(item)); positionModal.index = index">
              <dropdown-item-label
                icon="EditIcon"
                :label="$t('Edit')"
              />
            </b-dropdown-item>
            <b-dropdown-item @click="$delete(item.contactThreadPaymentScheduleItems, index)">
              <dropdown-item-label
                icon="TrashIcon"
                :label="$t('Delete')"
              />
            </b-dropdown-item>
          </div>
        </b-dropdown>
      </template>

      <!--      Empty      -->
      <template #empty>
        <empty-content />
      </template>

      <!--      Table Busy      -->
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner
            class="align-middle"
            variant="primary"
          />
        </div>
      </template>
    </b-table>

    <sw-select :name="$t('SelectDocumentType')">
      <v-select v-model="item.documentType"
                :reduce="e => e.type"
                :options="documentTypes"
      >
        <template #selected-option="{ label }">
          {{ $t(label) }}
        </template>
        <template #option="{ label }">
          {{ $t(label) }}
        </template>
      </v-select>
    </sw-select>

    <b-form-group v-if="item.documentType !== 'VAT'">
      <b-form-checkbox v-model="item.generateVatAfterPaid"
                       variant="primary"
      >
        {{ $t('GenerateWhenStatusPaid') }}
      </b-form-checkbox>
    </b-form-group>

    <!--    <div class="divider divider-left">-->
    <!--      <div class="divider-text text-primary">-->
    <!--        {{ $t('DocumentPositions') }}-->
    <!--      </div>-->
    <!--    </div>-->

    <!--    <div class="d-flex align-items-center">-->
    <!--      <b-button variant="primary"-->
    <!--                size="sm"-->
    <!--                @click="item.contactThreadPaymentScheduleItems.push({ name: newService.name, price: newService.price }); newService = { name: '', price: 0 }"-->
    <!--      >-->
    <!--        {{ $t('Add') }}-->
    <!--      </b-button>-->
    <!--    </div>-->

    <!--    <div class="mb-1">-->
    <!--      <b-list-group v-if="item.contactThreadPaymentScheduleItems.length">-->
    <!--        <b-list-group-item v-for="(service, index) in item.contactThreadPaymentScheduleItems"-->
    <!--                           :key="index"-->
    <!--                           class="d-flex justify-content-between align-items-center"-->
    <!--        >-->
    <!--          <div>-->
    <!--            {{ service.name }} <b-badge>{{ service.price }}</b-badge>-->
    <!--          </div>-->
    <!--          <div>-->
    <!--            <b-button variant="flat-danger"-->
    <!--                      size="sm"-->
    <!--                      @click="$delete(item.contactThreadPaymentScheduleItems, index)"-->
    <!--            >-->
    <!--              <sw-icon icon="TrashIcon" />-->
    <!--            </b-button>-->
    <!--          </div>-->
    <!--        </b-list-group-item>-->
    <!--      </b-list-group>-->
    <!--      <div-->
    <!--        v-else-->
    <!--        class="p-1 text-center text-primary"-->
    <!--      >-->
    <!--        <feather-icon icon="XCircleIcon" />-->
    <!--        {{ $t('NoData') }}-->
    <!--      </div>-->
    <!--    </div>-->

    <!-- Określ dzień zakończenia kampanii -->
    <b-form-group>
      <b-form-checkbox v-model="item.turnOffSchedule">
        {{ $t('SpecifyEndScheduleDate') }}
      </b-form-checkbox>
    </b-form-group>

    <b-row>
      <b-col sm="12"
             md="6"
      >
        <sw-select :name="$t('SpecifyStartDocumentDate')">

          <sw-select-fpr>
            <flat-pickr v-model="item.scheduleStartDate"
                        class="form-control"
                        :config="{ locale: getCalendarLocale($i18n.locale) }"
            />
          </sw-select-fpr>
        </sw-select>
      </b-col>

      <b-col v-if="item.turnOffSchedule"
             sm="12"
             md="6"
      >
        <sw-select v-if="item.turnOffSchedule"
                   :name="$t('EndScheduleDate')"
        >
          <sw-select-fpr>
            <flat-pickr v-model="item.scheduleEndDate"
                        class="form-control"
                        :config="{ locale: getCalendarLocale($i18n.locale) }"
            />
          </sw-select-fpr>
        </sw-select>
      </b-col>
    </b-row>

    <sw-info-alert v-if="item.turnOffSchedule"
                   :name="$t('ScheduleEndInfo')"
    />

    <div>
      <sw-action-button ref="save-action"
                        label="Save"
                        siz="sm"
                        variant="primary"
                        @click="saveForm"
      />
    </div>

    <b-modal v-model="positionModal.show"
             hide-footer
             :title="$t(positionModal.index > -1 ? 'Edit' : 'AddItem')"
             @hidden="positionModal.show = false; positionModal.item = JSON.parse(JSON.stringify(defaultPosition)); positionModal.index = -1"
    >
      <b-form-group :label="$t('Name')">
        <b-form-input
          v-model="positionModal.item.name"
        />
      </b-form-group>

      <sw-select :name="$t('Unit')">
        <v-select
          v-model.trim="positionModal.item.quantityUnit"
          :options="units"
          :get-option-label="name => $t(`unit.${name}`)"
        />
      </sw-select>

      <b-form-group :label="$t('Offer.Amount')">
        <b-form-input
          v-model="positionModal.item.quantity"
          type="number"
          placeholder="0"
        />
      </b-form-group>

      <b-form-group :label="$t('Offer.NetPrice')">
        <b-form-input
          v-model="positionModal.item.priceNet"
          type="number"
          placeholder="0"
        />
      </b-form-group>

      <sw-select name="Vat">
        <v-select
          v-model="positionModal.item.vatPercentage"
          :options="[0, 8, 23]"
          :clearable="false"
        />
      </sw-select>

      <b-button
        type="button"
        variant="primary"
        size="sm"
        @click="savePositionItem"
      >
        {{ $t('Save') }}
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import { BTable, VBTooltip } from 'bootstrap-vue'
import currency from '@/helpers/currency'
import flatPickr from 'vue-flatpickr-component'
import keysCuter from '@/helpers/keysCuter'
import axiosIns from '@/libs/axios'
import units from '@/helpers/productUnits'
import { mapGetters } from 'vuex'

//     "": "Rodzaj harmonogramu",
//     "": "Podaj wartość netto płatności w harmonogramie",
//     "": "Wybierz rodzaj dokumentu",
//     "": "Generuj automatycznie faktury VAT z opłaconych dokumentów faktura pro forma",
//     "": "Określ dzień startowy wystawienia dokumentu",
//     "": "Tryb wystawienia dokumentu",
//     "": "Określ ilość wystawianych dokumentów w miesiącu",
//     "": "Data sprzedaży usługi",
//     "": "Termin płatności",
//     "": "Pozycje dokumentu",
//     "": "Określ datę końcową harmonogramu",
//     "": "Data zakończenia harmonogramu",
//     "CreateSchedule": "Utwórz harmonogram",
//     "ScheduleEndInfo": "W tym dniu harmonogram zostanie wyłączony",

const blankSchedule = {
  name: '',
  type: 'CUSTOM',
  documentType: 'PRO_FORMA',
  // netPrice: '',
  // netPriceCurrency: 'PLN',
  generateVatAfterPaid: false,
  scheduleStartDate: '',
  documentIssuingType: '',
  maxPaymentsPerMonth: '',
  serviceSellDate: 'CURRENT_MONTH',
  paymentDaysCount: '7',
  contactThreadPaymentScheduleItems: [],
  turnOffSchedule: false,
  scheduleEndDate: '',
  status: 'ACTIVE',
}

const defaultPosition = {
  name: '',
  priceGross: 0,
  priceGrossTotal: 0,
  priceNet: 0,
  priceNetTotal: 0,
  quantity: 1,
  quantityUnit: 'PIECE',
  vatPercentage: 23,
}

export default {
  name: 'AddGoogleAdsForm',
  components: {
    vSelect,
    flatPickr,
    BTable,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    editedItem: {
      default: null,
      required: true,
    },
  },

  data: () => ({
    units,
    defaultPosition,
    positionModal: {
      show: false,
      item: JSON.parse(JSON.stringify(defaultPosition)),
      index: -1,
    },

    positionFields: [
      { key: 'name', label: 'Name' },
      { key: 'priceNet', label: 'Offer.NetPrice' },
      { key: 'vatPercentage', label: 'Vat' },
      { key: 'priceGross', label: 'Offer.GrossPrice' },
      { key: 'quantity', label: 'Offer.Amount' },
      { key: 'quantityUnit', label: 'Unit' },
      { key: 'action', label: 'Action' },
    ],

    blankSchedule,
    services: [],
    newService: { name: '', price: 0 },
    item: JSON.parse(JSON.stringify(blankSchedule)),
    currencyList: currency(),

    accountTypes: [
      { type: 'CUSTOM', label: 'CustomSchedule' },
      { type: 'GOOGLE_ADS', label: 'GoogleAdsSchedule' },
    ],
    documentTypes: [
      { type: 'PRO_FORMA', label: 'ProFormaInvoice' },
      { type: 'VAT', label: 'VatInvoice' },
    ],

    documentIssuingTypes: [
      { type: 'MONTHLY', label: 'OncePerMonth' },
      { type: 'DEPENDENCY', label: 'ManyTimesAMonth' },
    ],
    serviceSellDates: [
      { type: 'CURRENT_MONTH', label: 'CurrentMonth' },
    ],

  }),

  computed: {
    ...mapGetters({ settings: 'system/getSettings' }),
  },

  watch: {
    editedItem: {
      deep: true,
      handler() {
        this.initEditedItem()
      },
    },
  },
  mounted() {
    this.initEditedItem()
  },

  methods: {
    initEditedItem() {
      if (this.editedItem) {
        Object.entries(this.editedItem).forEach(([key, value]) => {
          console.log(key, value)
          if (value?.date) {
            if (key === 'scheduleEndDate') {
              this.$set(this.item, 'turnOffSchedule', true)
            }
            this.$set(this.item, key, value.date)
          } else {
            this.$set(this.item, key, value)
          }
        })
      }
    },
    savePositionItem() {
      const itemCopy = JSON.parse(JSON.stringify(this.positionModal.item))

      const totalNetPrice = parseFloat(parseFloat((Number(itemCopy.priceNet) * Number(itemCopy.quantity))).toFixed(2))
      const totalGrossPrice = parseFloat(parseFloat(totalNetPrice * (1 + Number(itemCopy.vatPercentage) / 100)).toFixed(2))
      const grossPrice = parseFloat(parseFloat(itemCopy.priceNet * (1 + Number(itemCopy.vatPercentage) / 100)).toFixed(2))

      itemCopy.priceGrossTotal = totalGrossPrice
      itemCopy.priceNetTotal = totalNetPrice
      itemCopy.priceGross = grossPrice

      if (this.positionModal.index > -1) {
        this.$set(this.item.contactThreadPaymentScheduleItems, this.positionModal.index, itemCopy)
      } else {
        this.item.contactThreadPaymentScheduleItems.push(itemCopy)
      }

      this.$set(this, 'positionModal', {
        show: false,
        item: JSON.parse(JSON.stringify(this.defaultPosition)),
        index: -1,
      })
    },
    async saveForm() {
      this.$refs['save-action'].setLoading(true)
      const copy = JSON.parse(JSON.stringify(this.item))

      // copy.netPrice = parseFloat(copy.netPrice)
      copy.paymentDaysCount = parseFloat(copy.paymentDaysCount)
      // copy.scheduleStartDate = parseFloat(copy.scheduleStartDate)
      copy.maxPaymentsPerMonth = parseFloat(copy.maxPaymentsPerMonth)

      copy.contactThread = this.$route.params.threadId

      if (!copy.turnOffSchedule) {
        copy.scheduleEndDate = null
      }

      copy.contactThreadPaymentScheduleItems = copy.contactThreadPaymentScheduleItems.map(e => ({
        ...e,
        name: e.name,
        priceGross: Number(e.priceGross),
        priceGrossTotal: Number(e.priceGrossTotal),
        priceNet: Number(e.priceNet),
        priceNetTotal: Number(e.priceNetTotal),
        quantity: Number(e.quantity),
        quantityUnit: e.quantityUnit,
        vatPercentage: Number(e.vatPercentage),
      }))

      keysCuter(copy)

      try {
        const resp = copy?.id ? await axiosIns.patch('1/contacts/threads/paymentSchedule', { ...copy }) : await axiosIns.post('1/contacts/threads/paymentSchedule', { ...copy, status: 'ACTIVE' })
        this.showToast('success', this.$i18n.t('success.contactUpdated'))

        this.item = JSON.parse(JSON.stringify(this.blankSchedule))
        this.$emit('save', { response: resp, item: copy })
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.$refs['save-action'].setLoading(false)
      }
    },
  },
}
</script>

<style scoped>

</style>
